import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/service/http.service';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  constructor(
    private http: HttpService
  ) { }

  public getConfigDetails() {
    return this.http.getData('cred');
  }

  public updateConfigDetails(data: any) {
    return this.http.postData('cred', data)
  }
}
