<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <a role="button" class="text-dark" (click)="goBack()">
                        <app-feather-icons [icon]="'arrow-left'" class="font-primary "></app-feather-icons>
                    </a>
                    <div class="image-container mx-auto mb-3">
                        <!-- [innerHtml]="rowData?.profilePic" -->
                        <img [src]="customerDetails?.profilePic != ''?customerDetails?.profilePic:'assets/images/user-img.jpg'"
                            onerror="this.onerror=null;this.src='../../../../assets/images/user-img.jpg';"
                            class="h-100 w-100 rounded-circle" alt="">
                    </div>
                    <div class="row px-3 justify-content-center mb-2">
                        <div class="col-md-8 col-lg-6 col-xl-5 col-12 font-weight-bolder">
                            <div class="mb-4">
                                <div class="form-group position-relative m-2 py-2 px-0">
                                    <label class="font-weight-light mb-0">User Name</label>
                                    <input type="text" readonly [value]="customerDetails?.username"
                                        class="form-control text-capitalize border-0 bg-transparent input-lg" />
                                </div>
                            </div>
                            <div class="mb-4">
                                <div class="form-group position-relative m-2 py-2 px-0">
                                    <label class="font-weight-light mb-0">Email</label>
                                    <input type="text" readonly [value]="customerDetails?.email"
                                        class="form-control border-0 bg-transparent input-lg" />
                                </div>
                            </div>
                            <div class="mb-4">
                                <div class="form-group position-relative m-2 py-2 px-0">
                                    <label class="font-weight-light mb-0">Contact</label>
                                    <input type="text" readonly [value]="customerDetails?.mobilenumber"
                                        class="form-control border-0 bg-transparent input-lg" />
                                </div>
                            </div>
                            <div class="mb-4">
                                <div class="form-group position-relative m-2 py-2 px-0">
                                    <label class="font-weight-light mb-0">Status</label>
                                    <input type="text" readonly [value]="customerDetails?.isBlocked"
                                        class="form-control border-0 bg-transparent input-lg" />
                                </div>
                            </div>
                            <div class="mb-4">
                                <div class="form-group position-relative m-2 py-2 px-0">
                                    <label class="font-weight-light mb-0">Profile Created</label>
                                    <input type="text" readonly [value]="customerDetails?.createdAt"
                                        class="form-control border-0 bg-transparent input-lg" />
                                </div>
                            </div>
                            <div class="mb-4" *ngIf="rowData?.connections?.length>0">
                                <div class="form-group position-relative m-2 py-2 px-0">
                                    <label class="font-weight-light mb-0">Connections</label>
                                    <input type="text" readonly [value]="rowData?.connections"
                                        class="form-control border-0 bg-transparent input-lg" />
                                </div>
                            </div>
                            <!-- <div class="mb-4" *ngIf="rowData?.familBook?.length>0">
                                <div class="form-group position-relative m-2 py-2 px-0">
                                    <label class="font-weight-light mb-0">Phone Book</label>
                                    <input type="text" readonly [value]="customerDetails?.familBook"
                                        class="form-control border-0 bg-transparent input-lg" />
                                </div>
                            </div> -->
                            <div class="mb-4">
                                <div class="form-group position-relative m-2 py-2 px-0">
                                    <label class="font-weight-light mb-0">Area</label>
                                    <input type="text" readonly [value]="customerDetails?.area"
                                        class="form-control border-0 bg-transparent input-lg" />
                                </div>
                            </div>
                            <div class="mb-4">
                                <div class="form-group position-relative m-2 py-2 px-0">
                                    <label class="font-weight-light mb-0">City</label>
                                    <input type="text" readonly [value]="customerDetails?.city"
                                        class="form-control border-0 bg-transparent input-lg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>