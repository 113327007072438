<div class="card m-0">
    <div class="card-header bg-transparent">
        <div class="d-flex justify-content-between align-items-center">
            <h5 class="modal-title text-white f-w-600" id="exampleModalLabel">Images/Videos</h5>
            <button type="button" class="close" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-4 mb-4 img-container" *ngFor="let image of data?.images">
                <img class="h-100 w-100" src="{{image.url}}" (click)="viewImage(image.url)" alt="">
            </div>
            <div class="col-4 mb-4 position-relative d-flex justify-content-center align-items-center"
                *ngFor="let video of data?.videos">
                <video class="h-100 w-100" width="100%" #video pause loop muted (click)="viewImage(video.url)"
                    id="background-video" alt="...">
                    <source [src]="video.url" type="video/mp4">
                </video>
                <app-feather-icons [icon]="'play'" (click)="viewImage(video.url)" class="text-white position-absolute">
                </app-feather-icons>
            </div>
            <div class="col-12 mb-4" *ngIf="data?.images.length === 0 && data?.videos.length === 0">
                <p class="text-center">No images/videos found.</p>
            </div>
        </div>
    </div>
</div>

<div *ngIf="open" class="viewer-container w-100 h-100 bg-black position-fixed p-4">
    <div class="viewer-content w-100 h-100 bg-black">
        <button type="button" class="close" aria-label="Close" (click)="closeImage()">
            <span aria-hidden="true">&times;</span>
        </button>
        <img *ngIf="imageURL" class="main-img m-auto d-block position-relative" [src]="imageURL" onerror="this.onerror=null;this.src='assets/images/no-img-available.jpg';" />
        <video *ngIf="videoURL" autoplay class="main-img m-auto d-block position-relative" controls alt="...">
            <source [src]="videoURL" type="video/mp4">
            Your browser does not support the video tag.
        </video>
    </div>
</div>