<button class="border-0 bg-transparent" (click)="seeCaseDetails(rowData)" (click)="click.emit(rowData)">
    <app-feather-icons [icon]="'eye'" class="font-primary"></app-feather-icons>
</button>
<button class="border-0 bg-transparent" (click)="click.emit({rowData:rowData, action:'delete'})">
    <app-feather-icons [icon]="'trash-2'" class="font-primary"></app-feather-icons>
</button>

<button class="border-0 bg-transparent" title="View User Location" [routerLink]="['case-location']"
    [queryParams]="{id: rowData._id}">
    <app-feather-icons [icon]="'map-pin'" class="font-primary"></app-feather-icons>
</button>