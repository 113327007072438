import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
// import jwt_decode from 'jwt-decode';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { ResponseModel } from 'src/app/shared/models/response';
import { HttpService } from 'src/app/shared/service/http.service';

const token_key = environment?.token_key || '';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  private isAuthenticated: boolean = false;
  private baseUrl: string = `${environment.apiUrl}/auth/admin`;
  private decodedObj: Object = {};

  constructor(private http: HttpClient, private router: Router, private httpService: HttpService) {
    this.loadCredentials();
  }

  getToken() {
    return localStorage.getItem(token_key);
  }

  saveToken(token) {
    localStorage.setItem(token_key, token);
    this.loadCredentials();
  }

  forgotPassword(data: any) {
    return this.httpService.postData('auth/forgotPasswordForAdmin', data)
  }

  verifyOtp(data: any) {
    return this.httpService.postData('auth/verifyOtp', data);
  }

  resetPassword(data:any){
    return this.httpService.postData('auth/resetPasswordForAdmin', data);
  }

  getAuth() {
    return this.isAuthenticated;
  }

  clearStorage() {
    localStorage.clear();
    this.isAuthenticated = false;
    this.decodedObj = {};
  }

  loadCredentials() {
    const token = this.getToken();
    if (token) {
      // this.decodedObj = jwt_decode(token);
      this.isAuthenticated = true;
    }
    else {
      this.clearStorage();
    }
    this.checkTokenExpiration();
  }

  logout() {
    localStorage.removeItem(token_key)
    this.clearStorage();
    this.router.navigateByUrl('/auth/login');
  }

  checkTokenExpiration() {
    return this.http.get<any>(`${this.baseUrl}/ping`);
  }

}