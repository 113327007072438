<div class="tab-pane fade active show" id="account" role="tabpanel" aria-labelledby="account-tab">
    <form *ngIf="!verifyOtp && !resetPass" [formGroup]="forgotPasswordForm" #userforgotPasswordForm="ngForm"
        class="form-horizontal auth-form" novalidate="">
        <div class="form-group">
            <input required="" name="login[username]" type="email" class="form-control" placeholder="Email"
                formControlName="email">
        </div>
        <div class="form-button">
            <button class="btn btn-primary" type="submit" (click)="onforgotPass()">Send</button>
        </div>
    </form>
    <div *ngIf="verifyOtp && !resetPass">
        <div class="input">
            <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{length:4}"></ng-otp-input>
        </div>
        <div class="form-button">
            <button class="btn btn-primary" type="submit" (click)="verify()">Verify Otp</button>
        </div>
    </div>
    <form *ngIf="resetPass" [formGroup]="resetPasswordForm" #userResetPassForm="ngForm"
        class="form-horizontal auth-form" novalidate="">
        <div class="form-group">
            <input required="" name="login[password]" type="password" class="form-control" formControlName="password"
                placeholder="Password">
        </div>

        <div class="form-group">
            <input required="" name="login[password]" type="password" class="form-control"
                formControlName="confirmPassword" placeholder="Confirm Password">
        </div>
        <div class="form-button">
            <button class="btn btn-primary" type="submit" (click)="resetPAssword()">Reset Password</button>
        </div>
    </form>
</div>