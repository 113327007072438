<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <div class="mb-3">
                        <div class="d-flex align-items-center justify-content-between">
                            <input type='text' class="filter-ngx form-control w-auto" placeholder='Search user'
                                (ngModelChange)="pageChange(1)" [(ngModel)]="searchTerm" />

                            <button type="button" class="btn custom-button rounded text-white px-3 py-2"
                                (click)="exportexcel()">Export
                                Data</button>
                        </div>
                    </div>
                    <div id="appendDiv">

                    </div>

                    <div id="batchDelete" class="category-table custom-datatable">
                        <div class="table-responsive">
                            <ng2-smart-table id="excel-table" [settings]="settings"
                                [source]="customers | paginate:{itemsPerPage: limit,currentPage: pageNo,totalItems: totalCustomer?.total}">
                            </ng2-smart-table>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end mt-4">
                        <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="pageChange($event)">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->
<!-- <app-common-loader></app-common-loader> -->