import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppService } from 'src/app/shared/service/app.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  isSubmitted = false;
  public loginForm: FormGroup;
  profileData = {} as any;
  public forgotPass: boolean = false;
  @ViewChild(FormGroupDirective) userLoginForm!: NgForm;
  private readonly destroyer$: Subject<void> = new Subject();
  constructor(private formBuilder: FormBuilder, private authenticationService: AuthenticationService, private router: Router) {
    this.createLoginForm();
  }

  private createLoginForm(): void {
    this.loginForm = this.formBuilder.group({
      email: [''],
      password: [''],
    })
  }

  ngOnInit() {
    this.authenticationService.logout();
  }

  ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }

  get f() {
    return this.loginForm.controls;
  }

  onUserLogin(): void {
    this.isSubmitted = true;
    if (this.isSubmitted && this.loginForm.valid) {
      let param = {
        email: this.loginForm.value.email,
        password: this.loginForm.value.password
      }
      this.authenticationService.login(param)
        .pipe(takeUntil(this.destroyer$))
        .subscribe(response => {
          if (response.success == true) {
            this.profileData = response.data?.userInfo;
            localStorage.setItem("adminProfile", JSON.stringify(this.profileData))
            this.userLoginForm.resetForm();
            this.router.navigate(['dashboard/default']);
          }
        }, error => {
          console.error(error);
        })
    }
  }

  public forgotPassword(): void {
    this.forgotPass = true
  }

  resetSuccess(event: any) {
    if (event)
      this.forgotPass = false
  }

}
