import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/service/http.service';
import { UrlsService } from 'src/app/shared/service/urls.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private urlsService: UrlsService, private http: HttpService) { }

  getCardDetails() {
    return this.http.getData('dashboard/get');
  }
}

export interface Cards {
  data: {
    categories: number;
    products: number;
    users: {
      userActiveCount: number;
      userInactiveCount: number;
    }
  }
}
