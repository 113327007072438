import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-custom-buttons',
  templateUrl: './custom-buttons.component.html',
  styleUrls: ['./custom-buttons.component.scss']
})
export class CustomButtonsComponent implements OnInit {
  @Input() rowData!: any;
  @Output() click: EventEmitter<any> = new EventEmitter(this.rowData);
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  seeUserDetails(rowData?) {
    this.router.navigate(['users/users/users-details'], { queryParams: { id: rowData._id }, });
  }

}
