<div class="card m-0">
    <div class="card-header border-bottom bg-transparent">
        <div class="d-flex justify-content-between align-items-center">
            <h5 class="modal-title f-w-600" id="exampleModalLabel">Reported By</h5>
            <button type="button" class="close" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <div class="card-body">
        <div class="row py-3 mb-3 mx-3 data-container" *ngFor="let user of rowData?.reportList">
            <div class="col-3">
                <div class="img-container">
                    <img class="h-100 w-100" src="{{user?.userId?.profilePic}}"
                        onerror="this.onerror=null;this.src='assets/images/user-img.jpg';" alt="">
                </div>
            </div>
            <div class="col-9 my-auto">
                <p class="mb-0"><span class="text-dark"> Name:</span> {{user?.userId?.username}}</p>
                <p class="mb-0"><span class="text-dark">Email: </span>{{user?.userId?.email}}</p>
                <p class="mb-0"><span class="text-dark">Reason: </span>{{user?.reason}}</p>
            </div>
        </div>
    </div>
</div>