<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <editor apiKey="w672a9u5bwsz5stwafq0zvrhd7pq98pskwiycfv6iix2wxpz" [init]="{plugins: 'link'}" [(ngModel)]="description"></editor>
            <div class="modal-footer border-0 py-3">
                <button type="button" class="btn custom-button text-white" *ngIf="!showButton"
                    (click)="addFaq()">Add</button>
                <button type="button" class="btn custom-button text-white" *ngIf="showButton"
                    (click)="updateFaq()">Update</button>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->
