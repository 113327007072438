import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-common-loader',
  templateUrl: './common-loader.component.html',
  styleUrls: ['./common-loader.component.scss']
})
export class CommonLoaderComponent implements OnInit {
  showSpinner = true;
  constructor(private modalsService: NgbModal) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.showSpinner = false;
      this.modalsService.dismissAll();
    }, 2500);
  }


}
