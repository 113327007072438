import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewImagesComponent } from '../../cases/view-images/view-images.component';
import { ViewReportedByUsersComponent } from '../view-reported-by-users/view-reported-by-users.component';

@Component({
  selector: 'app-custom-column',
  templateUrl: './custom-column.component.html',
  styleUrls: ['./custom-column.component.scss']
})
export class CustomColumnComponent implements OnInit {
  @Input() rowData: any;
  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  viewUsers(rowData) {
    const modalRef = this.modalService.open(ViewReportedByUsersComponent, { centered: true, size: 'lg' })
    modalRef.componentInstance.rowData = rowData
  }

  viewImages(rowData) {
    const modalRef = this.modalService.open(ViewImagesComponent, { centered: true, size: 'md' })
    modalRef.componentInstance.data = { images: rowData?.allImages, videos: rowData?.videos };
  }

}
