import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomerService } from '../service/custmers.service';

@Component({
  selector: 'app-view-user-location',
  templateUrl: './view-user-location.component.html',
  styleUrls: ['./view-user-location.component.scss']
})
export class ViewUserLocationComponent implements OnInit, OnDestroy {
  zoom: number = 2;
  customerDetails: any
  // initial center position for the map
  lat: number;
  lng: number;

  userId: string;
  marker: any
  @ViewChild('infoWindow') infoWindow: any
  modalRef: NgbModalRef
  private readonly destroyer$: Subject<void> = new Subject();
  constructor(
    private _location: Location,
    private router: ActivatedRoute,
    private customerService: CustomerService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.router.queryParams.subscribe(param => {
      this.userId = param['id']
    })

    this.getUserById();
  }

  ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }

  getUserById() {
    this.customerService.getCustomerById(this.userId)
      .pipe(takeUntil(this.destroyer$))
      .subscribe(response => {
        if (response.success == true) {
          this.customerDetails = response.data
          this.lat = response.data.location[0]
          this.lng = response.data.location[1]
        }
      }, error => console.error(error))
  }

  goBack() {
    this._location.back();
  }

  clickedMarker(event: any, template: any) {
    this.modalRef = this.modalService.open(template, { size: 'sm', centered: true })
  }

  onClickInfoView(marker: any, template: any) {
    this.marker = marker;
    this.modalRef = this.modalService.open(template, { size: 'sm', centered: true })
  }

}

