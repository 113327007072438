<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <a role="button" class="text-dark" (click)="goBack()">
                        <app-feather-icons [icon]="'arrow-left'" class="font-primary "></app-feather-icons>
                    </a>

                    <div class="map-container">
                        <agm-map #gm class="h-100" [fitBounds]="true" [fullscreenControl]="true" [mapTypeControl]="true"
                            [latitude]="customerDetails?.location[1]" [longitude]="customerDetails?.location[0]"
                            [disableDefaultUI]="false">
                            <agm-marker [latitude]="customerDetails?.location[1]" [agmFitBounds]="true"
                                [iconUrl]="{url: './assets/images/Current_Location_indicator.svg' ,scaledSize: {width: 60,height: 60}}"
                                [longitude]="customerDetails?.location[0]"
                                (markerClick)="clickedMarker($event, userDeetails)">
                            </agm-marker>
                        </agm-map>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #userDeetails>
    <div class="modal-header justify-content-end border-0 pb-0">
        <app-feather-icons [icon]="'x'" class="text-white pointer" (click)="modalRef?.close()"></app-feather-icons>
    </div>
    <div class="modal-body pt-0">
        <div class="d-flex align-items-center justify-content-center mb-3">
            <div>
                <div class="img-container">
                    <img [src]="customerDetails?.profilePic" alt=""
                        onerror="this.onerror=null;this.src='../../../../assets/images/user-img.jpg';">
                </div>
            </div>
            <div class="ml-3">
                <h4 class="text-white mb-1">{{customerDetails?.username}}</h4>
                <p class="text-white mb-1 text-wrap">{{customerDetails?.email || 'N/A'}}</p>
                <p class="text-white mb-1">{{customerDetails?.address || 'N/A'}}</p>
            </div>
        </div>
    </div>
</ng-template>