<div class="container-fluid">
    <div class="row">
        <div class="col-xl-3 col-md-6 col-lg-4 col-sm-12 xl-50">
            <div class="card dashboard-card o-hidden widget-cards" [routerLink]="['/users/users']">
                <div class="bg-primary card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-4">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'users'" class="font-primary"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-8"><span class="m-0">Users</span>
                            <h3 class="mb-0"> <span class="counter">{{cardDetails?.users?.userActiveCount}}</span></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 col-lg-4 col-sm-12 xl-50">
            <div class="card dashboard-card o-hidden widget-cards" [routerLink]="['/cases/cases']">
                <div class="bg-primary card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-4">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'file-text'" class="font-primary"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-8"><span class="m-0">Missing Cases</span>
                            <h3 class="mb-0"> <span class="counter">{{cardDetails?.case}}</span></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-12 col-lg-6 col-xl-4">
            <div class="chart-box card mx-auto mb-4">
                <div id="userChartContainer" class="chart-container w-100">
                </div>
            </div>
        </div>
        <div class="col-12 col-md-12 col-lg-6 col-xl-4">
            <div class="chart-box card mx-auto mb-4">
                <div id="casesChartContainer" class="chart-container w-100">
                </div>
            </div>
        </div>
    </div>
</div>