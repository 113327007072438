import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardModule } from './components/dashboard/dashboard.module';
import { SharedModule } from './shared/shared.module';
import { SettingModule } from './components/setting/setting.module';
import { AuthModule } from './components/auth/auth.module';
import { HttpClientModule } from '@angular/common/http';
import { CustomersModule } from './components/customers/customers.module';
import { StaticContentModule } from './components/static-content/static-content.module';
import { RestrictInputDirectiveDirective } from './components/restrict-input-directive.directive';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { CustomColumnComponent } from './components/report/custom-column/custom-column.component';
import { ViewReportedByUsersComponent } from './components/report/view-reported-by-users/view-reported-by-users.component';
import { AgmCoreModule } from '@agm/core';
import { NgxPaginationModule } from 'ngx-pagination';
// import { AgmCoreModule } from '@agm/core';

@NgModule({
  declarations: [
    AppComponent,
    RestrictInputDirectiveDirective,
    CustomColumnComponent,
    ViewReportedByUsersComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    DashboardModule,
    SettingModule,
    AuthModule,
    SharedModule,
    HttpClientModule,
    CustomersModule,
    StaticContentModule,
    AgmCoreModule.forRoot({ apiKey: 'AIzaSyBxVt-WlSLwfCKtNy1_tepNeLaTBDdllFM' }),
    NgxPaginationModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent],

})
export class AppModule { }
