<button class="border-0 bg-transparent" [title]="rowData?.isBlocked!= 'Active'? 'Unblock User': 'Block User'"
    (click)="click.emit({rowData:rowData, action:'block'})">
    <app-feather-icons [icon]="'slash'" [ngClass]="rowData?.isBlocked!= 'Active'? 'text-success': 'text-danger'">
    </app-feather-icons>
</button>

<button class="border-0 bg-transparent btnViewUser" title="View User" [routerLink]="['users-details']"
    [queryParams]="{id: rowData._id}">
    <app-feather-icons [icon]="'eye'" class="font-primary"></app-feather-icons>
</button>

<button class="border-0 bg-transparent" title="Delete User" (click)="click.emit({rowData:rowData, action:'delete'})">
    <app-feather-icons [icon]="'trash-2'" class="font-primary"></app-feather-icons>
</button>

<button class="border-0 bg-transparent" title="View User Location" [routerLink]="['users-location']"
    [queryParams]="{id: rowData._id}">
    <app-feather-icons [icon]="'map-pin'" class="font-primary"></app-feather-icons>
</button>