import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { doughnutData, pieData } from '../../shared/data/chart';
import { DashboardService } from './service/dashboard.service';
declare const CanvasJS: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public doughnutData = doughnutData;
  public pieData = pieData;
  public productList = []
  public orderList = []
  public cardDetails;
  public missingCasesGraphValues: any[] = [];
  public UserGraphValues: any[] = [];
  objectKeys: any
  months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  private readonly destroyer$: Subject<void> = new Subject();
  userDataPoints: any[] = [];
  missingCaseDataPoints: any[] = [];
  constructor(private dashboardService: DashboardService) {
    Object.assign(this, { doughnutData, pieData })
  }

  // events
  public chartClicked(e: any): void {
  }
  public chartHovered(e: any): void {
  }

  ngOnInit() {
    this.getCardDetails();

  }

  getCardDetails() {
    this.dashboardService.getCardDetails().subscribe(response => {
      if (response.success == true) {
        this.cardDetails = response.data;
        this.UserGraphValues = response.data.graph.userGraphMonthWise[0]?.monthWiseData;
        this.missingCasesGraphValues = response.data.graph.caseGraphMonthWise[0]?.monthWiseData;
        this.objectKeys = Object.keys;
        this.getMissingCasesChartData();
        this.getUserChartData();
      }
    })
  }

  getUserChartData() {
    var chart = new CanvasJS.Chart("userChartContainer",
      {
        animationEnabled: true,
        title: {
          text: "Total Users"
        },
        axisX: {
          includeZero: true,
        },
        axisY: {
          includeZero: true,
          gridThickness: 0,
        },
        data: [
          {
            type: "splineArea",
            markerSize: 3,
            color: "rgb(25,26,50)",
            xValueType: "dateTime",
            dataPoints: this.userDataPoints
          }
        ]
      });

    this.UserGraphValues.forEach((value: any, i) => {
      this.userDataPoints.push({
        x: value?.month,
        y: value?.usersCount,
        label: this.months[value?.month - 1],
        toolTipContent: `Month: ${this.months[value.month - 1]}, Users: ${value.usersCount}`
      })
    })
    chart.options.axisX.valueFormatString = 'YYYY';
    chart.render();
  }

  getMissingCasesChartData() {
    var chart = new CanvasJS.Chart("casesChartContainer",
      {
        animationEnabled: true,
        title: {
          text: "Total Missing Cases"
        },
        axisX: {
          includeZero: true,
        },
        axisY: {
          includeZero: true,
          gridThickness: 0,
        },
        data: [
          {
            type: "splineArea",
            markerSize: 3,
            color: "rgb(25,26,50)",
            xValueType: "dateTime",
            dataPoints: this.missingCaseDataPoints
          }
        ]
      });

    this.missingCasesGraphValues.forEach((value: any, i) => {
      this.missingCaseDataPoints.push({
        x: value.month,
        y: value.caseCount,
        label: this.months[value?.month - 1],
        toolTipContent: `Month: ${this.months[value.month - 1]}, Cases: ${value.caseCount}`
      })
    })
    chart.options.axisX.valueFormatString = 'YYYY';
    chart.render();
  }

}
