import { Injectable, HostListener, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { WINDOW } from "./windows.service";
// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	public screenWidth: any
	public collapseSidebar: boolean = false

	constructor(@Inject(WINDOW) private window) {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}
	}

	// Windows width
	@HostListener("window:resize", ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		{
			path: '/dashboard/default', title: 'Dashboard', icon: 'home', type: 'link', badgeType: 'primary', active: false
		},
		{
			// title: 'Users', icon: 'user-plus', type: 'sub', active: false, children: [
			path: '/users/users', icon: 'user-plus', title: 'Users', type: 'link'
			// ]
		},
		{
			// title: 'Cases', icon: 'file-text', type: 'sub', active: false, children: [
			path: '/cases/cases', icon: 'file-text', title: 'Cases', type: 'link'
			// ]
		},
		{
			// title: 'Alerts', icon: 'alert-octagon', type: 'sub', active: false, children: [
			path: '/alerts/alert-list', icon: 'alert-octagon', title: 'Alert List', type: 'link'
			// ]
		},
		{
			title: 'Static Content', icon: 'list', type: 'sub', active: false, children: [
				{ path: '/static-content/terms-and-conditions', title: 'Terms And Conditions', type: 'link' },
				{ path: '/static-content/privacy-policy', title: 'Privacy Policy', type: 'link' },
				{ path: '/static-content/faqs', title: 'FAQs', type: 'link' },
				{ path: '/static-content/contact-us', title: 'Contact Us', type: 'link' },
				// { path: '/static-content/help', title: 'Help', type: 'link' },
			]
		},
		{
			// title: 'Reports', icon: 'flag', type: 'sub', active: false, children: [
			path: '/report/user-reports', icon: 'flag', title: 'User Report', type: 'link'
			// ]
		},
		{
			// title: 'Social Feeds', icon: 'film', type: 'sub', active: false, children: [
			path: '/social-feeds/social-feeds', icon: 'film', title: 'Social Feeds', type: 'link'
			// ]
		},
	]
	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);


}
