import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  // Host URL
  public host = `${environment.apiUrl}`

  // Options
  public getOptions() {
    let headers = new HttpHeaders()
      .set(
        "Content-Type", "application/json")
    const httpOptions = {
      headers: headers,
    }
    return httpOptions;
  }

  // Options for body response
  public getOptionsWithBody() {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
    const httpOptions = {
      headers: headers,
      observe: 'response' as 'body'
    }
    return httpOptions;
  }

  //get header options without content-type
  public getFormDataOptions() {
    let formDataHeaders = new HttpHeaders()
    const formDataOptions = {
      headers: formDataHeaders,
      observe: 'response' as 'body'
    }
    return formDataOptions;
  }

  constructor(private http: HttpClient) { }

  postData(url: string, postData:any) : Observable<any> {
    return this.http.post(this.host + url, postData, this.getOptionsWithBody()).pipe(map(
      response => {
        return response;
      }
    ));
  }

  postFormData(url: string, postData: any): Observable<any> {
    return this.http.post(this.host + url, postData, this.getFormDataOptions()).pipe(map(
      response => {
        return response;
      }
    ))
  }

  putFormData(url: string, postData: any): Observable<any> {
    return this.http.put(this.host + url, postData, this.getFormDataOptions()).pipe(map(
      response => {
        return response;
      }
    ))
  }

  getData(urlWithQueryParam: string): Observable<any> {
    return this.http.get(this.host + urlWithQueryParam, this.getOptions()).pipe(map(
      response => {
        return response;
      }
    ))
  }

  putData(url: string, postData?: any): Observable<any> {
    return this.http.put(this.host + url, postData? postData: null, this.getOptionsWithBody()).pipe(map(
      response => {
        return response;
      }
    ))
  }

  deteleData(url: string): Observable<any> {
    return this.http.delete(this.host + url, this.getOptionsWithBody()).pipe(map(
      response => {
        return response;
      }
    ))
  }

}
