import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { ProfileService } from '../profile/profile.service';
import { SettingService } from '../setting.service';

@Component({
  selector: 'app-configuration-settings',
  templateUrl: './configuration-settings.component.html',
  styleUrls: ['./configuration-settings.component.scss']
})
export class ConfigurationSettingsComponent implements OnInit {
  public configSettingForm: FormGroup
  public configDetails: any;
  constructor(
    private formBuilder: FormBuilder,
    private settingService: SettingService
  ) { }

  ngOnInit(): void {
    this.setForm();
    this.getConfigDetails();
  }

  public setForm(): void {
    this.configSettingForm = this.formBuilder.group({
      contactNo: [''],
      apiKey: [''],
      sendGridKey: [''],
      sendGridEmail: ['']
    })
  }

  private getConfigDetails() {
    this.settingService.getConfigDetails()
      .subscribe(response => {
        if (response.success == true) {
          this.configDetails = response.data;
          this.patchValues(this.configDetails);
        }
      }, error => { console.error(error) })
  }

  private patchValues(configDetails: any) {
    this.configSettingForm.patchValue({
      contactNo: configDetails?.phone,
      apiKey: configDetails?.googlePlaceKey,
      sendGridKey: configDetails?.sendgridKey,
      sendGridEmail: configDetails?.defaultMail
    })
  }

  public saveDetails() {
    let param = {
      phone: this.configSettingForm.value.contactNo,
      sendgridKey: this.configSettingForm.value.sendGridKey,
      defaultMail: this.configSettingForm.value.sendGridEmail,
      googlePlaceKey: this.configSettingForm.value.apiKey,
    }

    this.settingService.updateConfigDetails(param)
      .subscribe(response => {
        if (response.body.success == true) {
          Swal.fire({
            icon: 'success',
            text: response.body.message,
          });
          this.getConfigDetails();
        }
      })
  }

}
