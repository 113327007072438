import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { StaticContentService } from '../service/static-content.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit, OnDestroy {
  addHelpDescForm: FormGroup;
  public description: string = '';
  public helpId: string = '';
  public showButton: boolean = false;
  isSubmitted: boolean = false;
  private readonly destroyer$: Subject<void> = new Subject();
  constructor(private formBuilder: FormBuilder, private staticContentService: StaticContentService) { }

  ngOnInit(): void {
    this.getHelp();
    this.setForm();
  }

  ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }

  setForm() {
    this.addHelpDescForm = this.formBuilder.group({
      description: ['', [Validators.required]],
    })
  }

  getHelp() {
    this.staticContentService.getHelp()
      .pipe(takeUntil(this.destroyer$))
      .subscribe(response => {
        if (response.success == true && response.data.length != 0) {
          this.helpId = response?.data[0]?._id;
          this.description = response?.data[0]?.description;
          this.showButton = true;
        }
      })
  }

  addHelpDescription() {
    let param = {
      description: this.description
    }
    this.isSubmitted = true;
    if (this.isSubmitted && this.description != '') {
      this.staticContentService.addHelp(param)
        .pipe(takeUntil(this.destroyer$))
        .subscribe(response => {
          if (response.body.success == true) {
            Swal.fire({
              icon: 'success',
              text: response.body.message,
            });
            this.getHelp();
            this.isSubmitted = false;
          }
        }, error => {
          Swal.fire({
            icon: 'error',
            text: error,
          })
        })
    }
  }

  updateDescription() {
    let param = {
      description: this.description
    }
    this.staticContentService.updateHelp(this.helpId, param)
      .pipe(takeUntil(this.destroyer$))
      .subscribe(response => {
        if (response.body.success == true) {
          Swal.fire({
            icon: 'success',
            text: response.body.message,
          });
          this.getHelp();
        }
      }, error => {
        Swal.fire({
          icon: 'error',
          text: error,
        })
      })
  }

}
