import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomersRoutingModule } from './customers-routing.module';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CutomersComponent } from './cutomers/cutomers.component';
import { CustomButtonsComponent } from './custom-buttons/custom-buttons.component';
import { CustomerDetailsComponent } from './customer-details/customer-details.component';
import { ViewUserLocationComponent } from './view-user-location/view-user-location.component';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [CutomersComponent, CustomButtonsComponent, CustomerDetailsComponent, ViewUserLocationComponent],
  imports: [
    CommonModule,
    CustomersRoutingModule,
    Ng2SmartTableModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
  ]
})
export class CustomersModule { }
