import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { FaqsComponent } from './faqs/faqs.component';
import { HelpComponent } from './help/help.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'faqs',
        component: FaqsComponent,
        data: {
          title: "FAQs",
          breadcrumb: "FAQs"
        }
      },
      {
        path: 'contact-us',
        component: ContactUsComponent,
        data: {
          title: "Contact Us",
          breadcrumb: "Contact Us"
        }
      },
      {
        path: 'terms-and-conditions',
        component: TermsAndConditionsComponent,
        data: {
          title: "Terms And Conditions",
          breadcrumb: "Terms And Conditions"
        }
      },
      {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent,
        data: {
          title: "Privacy Policy",
          breadcrumb: "Privacy Policy"
        }
      },
      {
        path: 'help',
        component: HelpComponent,
        data: {
          title: "Help",
          breadcrumb: "Help"
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StaticContentRoutingModule { }
