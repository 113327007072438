import { Routes } from '@angular/router';
import { AuthGuard } from '../service/auth.guard';

export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'users',
    loadChildren: () => import('../../components/customers/customers.module').then(m => m.CustomersModule),
    data: {
      breadcrumb: "Users"
    }
  },
  {
    path: 'cases',
    loadChildren: () => import('../../components/cases/cases.module').then(m => m.CasesModule),
    data: {
      breadcrumb: "Cases"
    }
  },
  {
    path: 'alerts',
    loadChildren: () => import('../../components/alerts/alerts/alerts.module').then(m => m.AlertsModule),
    data: {
      breadcrumb: "Alerts"
    }
  },
  {
    path: 'static-content',
    loadChildren: () => import('../../components/static-content/static-content.module').then(m => m.StaticContentModule),
    data: {
      breadcrumb: "Static Content"
    }
  },
  {
    path: 'report',
    loadChildren: () => import('../../components/report/report/report.module').then(m => m.ReportModule),
    data: {
      breadcrumb: "Report"
    }
  },
  {
    path: 'social-feeds',
    loadChildren: () => import('../../components/social-feeds/social-feed.module').then(m => m.SocialFeedModule),
    data: {
      breadcrumb: "Social Feeds"
    }
  },
  {
    path: 'settings',
    loadChildren: () => import('../../components/setting/setting.module').then(m => m.SettingModule),
    data: {
      breadcrumb: "Settings"
    },
    canActivate: [AuthGuard]
  },
];