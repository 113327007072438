import { Component, OnInit } from '@angular/core';
import { AdminProfile } from 'src/app/shared/service/admin-profile.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  public profile = {} as AdminProfile
  constructor() { }

  ngOnInit() {
    let profileData = localStorage.getItem('adminProfile');
    this.profile = JSON.parse(profileData);
  }

}
