<div class="page-wrapper">
    <div class="authentication-box">
        <div class="container">
            <div class="row">
                <div class="col-md-5 p-0 card-left">
                    <div class="card bg-primary logo-card">
                        <div class="svg-icon">
                            <!-- <img src="../../../../assets/images/dashboard/multikart-logo.png" class="h-100 w-100" alt=""> -->
                            <img class="h-100 w-100" src="../../../../assets/images/logo 911missing.png" alt="">
                        </div>
                        <div class="single-item">
                            <div class="item">
                                <div>
                                    <h3>Welcome To 911 Missing Admin</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-7 p-0 card-right" *ngIf="!forgotPass">
                    <div class="card tab2-card">
                        <div class="card-body">
                            <ngb-tabset class="tab-coupon mb-0">
                                <ngb-tab>
                                    <ng-template ngbTabTitle><i class="icon-user mr-2"></i>Login</ng-template>
                                    <ng-template ngbTabContent>
                                        <div class="tab-pane fade active show" id="account" role="tabpanel"
                                            aria-labelledby="account-tab">
                                            <form [formGroup]="loginForm" #userLoginForm="ngForm"
                                                class="form-horizontal auth-form" novalidate="">
                                                <div class="form-group">
                                                    <input required="" name="login[username]" type="email"
                                                        class="form-control" placeholder="Email"
                                                        formControlName="email">
                                                </div>

                                                <div class="form-group">
                                                    <input required="" name="login[password]" type="password"
                                                        class="form-control" formControlName="password"
                                                        placeholder="Password">
                                                </div>
                                                <div class="form-terms">
                                                    <div class="custom-control custom-checkbox mr-sm-2">
                                                        <!-- <input type="checkbox" class="custom-control-input"
                                                            id="customControlAutosizing">
                                                        <label class="custom-control-label"
                                                            for="customControlAutosizing">Remember me</label> -->
                                                        <a href="javascript:void(0)" class="btn btn-default forgot-pass"
                                                            (click)="forgotPassword()">Forgot password?</a>
                                                    </div>
                                                </div>
                                                <div class="form-button">
                                                    <button class="btn btn-primary" type="submit"
                                                        (click)="onUserLogin()">Login</button>
                                                </div>
                                            </form>
                                        </div>
                                    </ng-template>
                                </ngb-tab>
                            </ngb-tabset>
                        </div>
                    </div>
                </div>

                <div class="col-md-7 p-0 card-right" *ngIf="forgotPass">
                    <div class="card tab2-card">
                        <div class="card-body">
                            <ngb-tabset class="tab-coupon mb-0">
                                <ngb-tab>
                                    <ng-template ngbTabTitle><i class="icon-user mr-2"></i>Forgot Password</ng-template>
                                    <ng-template ngbTabContent>
                                        <app-forgot-password (resetSuccess)="resetSuccess($event)">
                                        </app-forgot-password>
                                    </ng-template>
                                </ngb-tab>
                            </ngb-tabset>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>