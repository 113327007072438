import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';


import { FeatherIconsComponent } from './components/feather-icons/feather-icons.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';

import { ToggleFullscreenDirective } from "./directives/fullscreen.directive";
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';
import { NavService } from './service/nav.service';
import { WINDOW_PROVIDERS } from './service/windows.service';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { RightSidebarComponent } from './components/right-sidebar/right-sidebar.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './service/jwt.interceptor';
import { ErrorInterceptor } from './service/error.interceptor';
import { SearchPipe } from './pipe/search.pipe';
import { CommonLoaderComponent } from './components/common-loader/common-loader.component';
import { CustomButtonsComponent } from './components/custom-buttons/custom-buttons.component';
import { AgmCoreModule } from '@agm/core';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
  declarations: [
    ToggleFullscreenDirective,
    FeatherIconsComponent,
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    ContentLayoutComponent,
    BreadcrumbComponent,
    RightSidebarComponent,
    SearchPipe,
    CommonLoaderComponent,
    CustomButtonsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    AgmCoreModule.forRoot({ apiKey: 'AIzaSyBxVt-WlSLwfCKtNy1_tepNeLaTBDdllFM' }),
    NgxPaginationModule
  ],
  providers: [
    NavService, WINDOW_PROVIDERS,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  exports: [
    FeatherIconsComponent,
    CommonLoaderComponent,
    ToggleFullscreenDirective,
    AgmCoreModule,
    NgxPaginationModule
  ]
})
export class SharedModule { }
