import { FormControl } from '@angular/forms';
import { Component } from '@angular/core';
import { AppService } from '../service/app.service';


@Component({
    template: `
  <input style=" border: 2px solid #6c5ce7;
  padding: .2em .4em;
  width:auto;
  border-radius: .2em;
  background-color: #a29bfe;
  transition: 1s;"
                type="file"
                (change)="updateValue($event)"
        />
  `,
})
export class CustomFilterComponent {
    inputControl = new FormControl();
    public picFile: any
    public picFileLink: any

    constructor(private productService: AppService) {
        // super();
    }
    updateValue(event) {
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (_event) => {
            this.picFileLink = event.srcElement.files[0];
            this.picFile = reader.result;
            this.productService.user.next(this.picFileLink)
        }
    }
    ngOnInit() {

    }

}