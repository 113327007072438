import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StaticContentService } from '../service/static-content.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {
  faqs = [];
  closeResult: string
  addFaqForm: FormGroup;
  isSubmitted = false;
  @ViewChild('editor') editor;
  public description: string = '';
  public faqId: string = '';
  public showButton: boolean = false;
  public readonly destroyer$: Subject<void> = new Subject();
  constructor(private modalService: NgbModal, private formBuilder: FormBuilder, private staticContentService: StaticContentService) {
  }

  ngOnInit(): void {
    this.getFaq();
    this.createForm();
  }

  createForm() {
    this.addFaqForm = this.formBuilder.group({
      faQuestion: ['', [Validators.required]],
      description: ['', [Validators.required]]
    })
  }

  get f() {
    return this.addFaqForm.controls;
  }

  ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }

  setStyle(style: string) {
    let bool = document.execCommand(style, false, null);
  }

  getFaq() {
    this.staticContentService.getFaq()
      .pipe(takeUntil(this.destroyer$))
      .subscribe(response => {
        if (response.success == true && response.data.length != 0) {
          this.faqId = response?.data[0]?._id;
          this.description = response?.data[0]?.description;
          this.showButton = true;
          // this.deleteFaq(this.faqId)
        }
      }, error => {
        console.error(error);
      })
  }

  addFaq(): void {
    let param = {
      description: this.description
    }
    this.isSubmitted = true;
    if (this.isSubmitted && this.description != '') {
      this.staticContentService.addFaq(param)
        .pipe(takeUntil(this.destroyer$))
        .subscribe(response => {
          if (response.body.success == true) {
            Swal.fire({
              icon: 'success',
              text: response.body.message,
            });
            this.getFaq();
            this.isSubmitted = false;
          }
        }, error => {
          Swal.fire({
            icon: 'error',
            text: error,
          })
        })
    }
  }
  updateFaq() {
    let param = {
      description: this.description
    }
    this.staticContentService.updateFaq(this.faqId, param)
      .pipe(takeUntil(this.destroyer$))
      .subscribe(response => {
        if (response.body.success == true) {
          Swal.fire({
            icon: 'success',
            text: response.body.message,
          });
          this.getFaq();
        }
      }, error => {
        Swal.fire({
          icon: 'error',
          text: error,
        })
      })
  }

}
