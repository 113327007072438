import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { CustomerService } from '../service/custmers.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { CustomButtonsComponent } from '../custom-buttons/custom-buttons.component';
import { CustomFilterComponent } from 'src/app/shared/components/custom-file-uploader-component';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-cutomers',
  templateUrl: './cutomers.component.html',
  styleUrls: ['./cutomers.component.scss']
})
export class CutomersComponent implements OnInit, OnDestroy {
  customers = [];
  temp = [];
  totalCustomer: any;
  searchTerm: string = '';
  public limit = 10
  public pageNo = 1;
  private readonly destroyer$: Subject<void> = new Subject();
  constructor(
    private customerService: CustomerService,
  ) {
  }

  public settings = {
    edit: {
      confirmSave: true,
    },
    pager: {
      display: false,
      perPage: 10,
    },

    actions: {
      position: 'right',
      add: false,
      edit: false,
      delete: false,
    },
    columns: {
      sr_no: {
        title: 'Sr. No.',
      },
      name: {
        title: 'Name',
        type: 'html',
      },
      email: {
        title: 'Email'
      },
      contact: {
        title: 'Contact Number'
      },
      profilePic: {
        title: 'Picture',
        type: 'html',
        editor: {
          type: 'custom',
          component: CustomFilterComponent,
        }
      },
      location: {
        title: 'Location',
      },
      isBlocked: {
        title: "Active/Inactive"
      },
      Buttons: {
        title: 'Actions',
        type: 'custom',
        filter: false,
        renderComponent: CustomButtonsComponent,
        onComponentInitFunction: (instance) => {
          instance.click.subscribe((row: any) => {
            if (row.action == 'block')
              this.changeUserStatus(row.rowData);
            else if (row.action == 'delete')
              this.deleteUser(row.rowData)
          });
        }
      },
    },
  };

  ngOnInit(): void {
    this.getCustomer(this.pageNo, this.searchTerm);
  }

  ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }

  public exportexcel(): void {
    this.customerService.downloadUserList()
      .pipe(takeUntil(this.destroyer$))
      .subscribe(response => {
        if (response.status == 200) {
          this.downloadFile(response, 'user_list.xlsx')
        }
      }, error => console.error(error))
  }

  public downloadFile(response: any, fileName?: string) {
    const url = window.URL.createObjectURL(new Blob([response.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  }

  changeUserStatus(rowData: any) {
    if (rowData.isBlocked == 'Active') {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
      })
      swalWithBootstrapButtons.fire({
        title: 'Are you sure you want to deactivate this user?',
        // text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.customerService.blockUser(rowData._id)
            .pipe(takeUntil(this.destroyer$))
            .subscribe(response => {
              if (response.success == true) {
                Swal.fire({
                  icon: 'success',
                  text: response.message,
                });
                this.getCustomer(this.pageNo, this.searchTerm);
              }
            }, error => {
              Swal.fire({
                icon: 'error',
                text: error,
              })
            })
        } else if (
          result.dismiss === Swal.DismissReason.cancel
        ) {
          // swalWithBootstrapButtons.fire(
          //   'Cancelled'
          // )
        }
      })
    } else if (rowData.isBlocked != 'Active') {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
      })
      swalWithBootstrapButtons.fire({
        title: 'Are you sure you want to activate this user?',
        // text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.customerService.unblockUser(rowData._id)
            .pipe(takeUntil(this.destroyer$))
            .subscribe(response => {
              if (response.success == true) {
                Swal.fire({
                  icon: 'success',
                  text: response.message,
                });
                this.getCustomer(this.pageNo, this.searchTerm);
              }
            }, error => {
              Swal.fire({
                icon: 'error',
                text: error,
              })
            })
        } else if (
          result.dismiss === Swal.DismissReason.cancel
        ) {
          // swalWithBootstrapButtons.fire(
          //   'Cancelled'
          // )
        }
      })
    }
  }
  search(event) {
    this.customers = this.temp.filter((val) =>
      val?.name?.toLowerCase().includes(event.target.value.toLowerCase()) ||
      val?.email?.toLowerCase().includes(event.target.value.toLowerCase()) ||
      val?.contact?.includes(event.target.value)
    );
  }

  private getCustomer(page: any, search: any): void {
    this.customerService.getCustomer(page, this.limit, search)
      .pipe(takeUntil(this.destroyer$))
      .subscribe(response => {
        this.totalCustomer = response.data;
        const customerDataArr = []
        if (response.success) {
          response.data.docs.forEach((customer, i) => {
            let newObj = {};
            newObj = {
              sr_no: i + 1,
              name: customer?.username || '-',
              _id: customer?._id,
              isBlocked: customer?.isBlocked ? "Inactive" : "Active",
              email: customer?.email || '-',
              contact: customer?.mobilenumber || '-',
              profilePic: customer?.profilePic != '' ? `<img src=${customer?.profilePic} class='imgTable'>` : "<img  src=" + 'assets/images/user-img.jpg' + " class='imgTable'>",
              missingPersons: customer?.missingPersons,
              profileCreated: customer?.createdAt,
              connections: customer?.connections,
              phoneBook: customer?.phoneBook,
              area: customer?.area || '-',
              city: customer?.city || '-',
              location: `${customer?.city || '-'} ${customer?.state || ''}`
            };
            customerDataArr.push(newObj)
          })
          this.customers = customerDataArr;
          this.temp = customerDataArr;
        }
      }, error => {
        console.error(error);
      })
  }

  deleteUser(rowData?) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })
    swalWithBootstrapButtons.fire({
      title: 'Are you sure you want to delete this User?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.customerService.deleteCustomer(rowData._id)
          .subscribe(response => {
            if (response.body.success == true) {
              this.customers = this.customers.filter(user => user._id !== rowData._id);
              swalWithBootstrapButtons.fire(
                response.body.message,
                'success'
              )
              this.getCustomer(this.pageNo, this.searchTerm);
            }
          }, error => {
            Swal.fire({
              icon: 'error',
              text: error,
            })
          })
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        // swalWithBootstrapButtons.fire(
        //   'Cancelled',
        // )
      }
    })

  }

  pageChange(page: any) {
    this.pageNo = page ? page : 1;
    this.getCustomer(this.pageNo, this.searchTerm);
  }
}
