import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CutomersComponent } from './cutomers/cutomers.component';
import { AuthGuard } from 'src/app/shared/service/auth.guard';
import { CustomerDetailsComponent } from './customer-details/customer-details.component';
import { ViewUserLocationComponent } from './view-user-location/view-user-location.component';
const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'users',
        component: CutomersComponent,
        data: {
          title: "Users",
          breadcrumb: "Users"
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'users/users-details',
        component: CustomerDetailsComponent,
        data: {
          title: "User Details",
          breadcrumb: "User Details",
          rowData: {}
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'users/users-location',
        component: ViewUserLocationComponent,
        data: {
          title: "User Location",
          breadcrumb: "User Location",
          rowData: {}
        },
        canActivate: [AuthGuard]
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomersRoutingModule { }
