import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UrlsService {
  public apiServer = `${environment.apiUrl}`;
  constructor() { }

  //register admin
  register: string = 'auth/register/';
  registerAdmin: string = this.register + 'admin';

  // Customer
  CustomerBase: string = "user/";
  getCustomer: string = this.CustomerBase + 'get';
  addCustomer: string = this.CustomerBase + 'create/customer';
  updateCustomer: string = this.CustomerBase + 'update/';
  deleteCustomer: string = this.CustomerBase + 'delete/';

  // product
  product: string = 'product/';
  addProduct: string = this.product + 'create';
  getProducts: string = this.product + 'admin/get';
  deleteProduct: string = this.product + 'delete/';
  updateProduct: string = this.product + 'update/';

}
