import { DatePipe, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerService } from '../service/custmers.service';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss'],
  providers: [DatePipe]
})
export class CustomerDetailsComponent implements OnInit {
  rowData: any;
  createdDate: any;
  customerId: string
  customerDetails: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private _location: Location,
    private customerService: CustomerService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(data => {
      this.customerId = data['id']
      // this.createdDate = this.datePipe.transform(data?.profileCreated, 'MMM d, y')
      // this.rowData = data;

    })

    this.getCustomer();
  }

  private getCustomer() {
    this.customerService.getCustomerById(this.customerId)
      .subscribe(response => {
        if (response.success == true) {
          this.customerDetails = response.data
        }
      }, error => console.error(error))
  }

  goBack() {
    this._location.back();
  }
}
