import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from 'src/app/shared/service/http.service';
import { UrlsService } from 'src/app/shared/service/urls.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CustomerService {

    public showButton = new BehaviorSubject<string>('');
    public buttonValue = new BehaviorSubject<string>('');

    constructor(
        private urlsService: UrlsService,
        private http: HttpService,
        private httpClient: HttpClient
    ) { }

    addCustomer(data: any) {
        return this.http.postData(this.urlsService.addCustomer, data);
    }

    getCustomer(page: any, limit: any, search: any) {
        return this.http.getData(`${this.urlsService.getCustomer}?page=${page}&limit=${limit}&searchBy=${search}`);
    }

    getCustomerById(id: any) {
        return this.http.getData(`profile/getUserById/${id}`);
        // http://54.190.192.105:9131/api/V1/profile/getUserById/<userid>
    }

    updateCustomer(customerId: string, data: any) {
        return this.http.putData(this.urlsService.updateCustomer + customerId, data);
    }

    deleteCustomer(customerId: string) {
        return this.http.deteleData('user/delete/' + customerId);
    }

    blockUser(userId: string) {
        return this.http.getData('user/block/' + userId);
    }

    unblockUser(userId: string) {
        return this.http.getData('user/unblock/' + userId);
    }

    downloadUserList() {
        return this.httpClient.get(`${environment.apiUrl}user/downloadUser`, {
            observe: 'response',
            responseType: 'arraybuffer',
            headers: {
                "Content-Disposition": "attachment; filename=template.xlsx",
                "Content-Type":
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            }
        })
    }

}

export interface Customer {
    question: string,
    answer: string,
    _id: string
}


