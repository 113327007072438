import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from './http.service';
import { UrlsService } from './urls.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(private http: HttpService, private urlsService: UrlsService) { }

  public user = new BehaviorSubject<any>('');

  registerAdmin(data: any) {
    return this.http.postData(this.urlsService.registerAdmin, data);
  }

}
