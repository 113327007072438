import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-view-images',
  templateUrl: './view-images.component.html',
  styleUrls: ['./view-images.component.scss']
})
export class ViewImagesComponent implements OnInit {
  data: any;
  imageURL: string;
  videoURL: string;
  open: boolean = false;
  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  closeModal() {
    this.activeModal.close()
  }

  closeImage() {
    this.open = false;
    this.imageURL = '';
    this.videoURL = '';
  }

  viewImage(url?) {
    let validImage = [
      "tif", "tiff", "jpg", "jpeg", "gif", "png", "svg"
    ];
    let validVideo = [
      "mp4", 'ogg'
    ]
    if (validImage.includes(url.toLowerCase().split('.').pop())) this.imageURL = url;
    else if (validVideo.includes(url.toLowerCase().split('.').pop())) this.videoURL = url;
    this.open = true;
  }

}
