import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StaticContentService } from '../service/static-content.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  addConatctUsForm: FormGroup;
  isSubmitted = false;
  public showButton: boolean = false;
  contactId: string = '';
  description: string = '';
  public readonly destroyer$: Subject<void> = new Subject();
  constructor(private modalService: NgbModal, private formBuilder: FormBuilder, private staticContentService: StaticContentService) {
    // this.faqs = ConatctUsDB.faq;
  }

  ngOnInit(): void {
    this.createForm();
    this.getConatctUs();
  }
  createForm() {
    this.addConatctUsForm = this.formBuilder.group({
      description: ['', [Validators.required]],
    })
  }

  get f() {
    return this.addConatctUsForm.controls;
  }

  getConatctUs() {
    this.staticContentService.getContactUs()
      .pipe(takeUntil(this.destroyer$))
      .subscribe(response => {
        if (response.success && response.data.length != 0) {
          this.contactId = response?.data[0]?._id;
          this.description = response?.data[0]?.description;
          this.showButton = true;
        }
      }, error => {
        console.error(error);
      })
  }

  addContactUs() {
    let param = {
      // description: document.getElementById('editor').innerHTML
      description: this.description
    }
    this.isSubmitted = true;
    if (this.isSubmitted && this.description != '') {
      this.staticContentService.addContactUs(param)
        .pipe(takeUntil(this.destroyer$))
        .subscribe(response => {
          if (response.body.success == true) {
            Swal.fire({
              icon: 'success',
              text: response.body.message,
            });
            this.getConatctUs();
            this.isSubmitted = false;
          }
        }, error => {
          Swal.fire({
            icon: 'error',
            text: error,
          })
        })
    }
  }

  updateContactUs() {
    let param = {
      description: this.description
    }
    this.staticContentService.updateContactUs(this.contactId, param)
      .pipe(takeUntil(this.destroyer$))
      .subscribe(response => {
        if (response.body.success == true) {
          Swal.fire({
            icon: 'success',
            text: response.body.message,
          });
          this.getConatctUs();
        }
      }, error => {
        Swal.fire({
          icon: 'error',
          text: error,
        })
      })
  }

}
