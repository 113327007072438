import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  public forgotPasswordForm: FormGroup;
  public resetPasswordForm: FormGroup;
  public verifyOtp: boolean = false;
  public resetPass: boolean = false;
  private token: any;
  private otp: any;
  @Output() resetSuccess = new EventEmitter<boolean>();
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.createForgotPassForm();
  }

  private createForgotPassForm(): void {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required]]
    })
  }

  public onforgotPass() {
    let param = {
      email: this.forgotPasswordForm.value.email
    }
    this.authService.forgotPassword(param)
      .subscribe(response => {
        if (response.body.success == true) {
          this.verifyOtp = true;
          this.token = response.body.data.token
        }
      })
  }

  onOtpChange(event: any) {
    if (event.length == 4)
      this.otp = event
  }

  verify() {
    let param = {
      otp: this.otp,
      token: this.token
    }
    this.authService.verifyOtp(param)
      .subscribe(response => {
        if (response.body.success == true) {
          this.verifyOtp = false;
          this.resetPass = true;
          this.setResetPassForm()
        }
      })
  }

  setResetPassForm() {
    this.resetPasswordForm = this.formBuilder.group({
      password: [''],
      confirmPassword: ['']
    })
  }

  resetPAssword() {
    let param = {
      email: this.forgotPasswordForm.value.email,
      newPassword: this.resetPasswordForm.value.password,
      confirmPassword: this.resetPasswordForm.value.confirmPassword
    }
    this.authService.resetPassword(param).subscribe(response => {
      if (response.body.success == true) {
        this.resetSuccess.emit(true);
      }
    })
  }

}
