import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-view-reported-by-users',
  templateUrl: './view-reported-by-users.component.html',
  styleUrls: ['./view-reported-by-users.component.scss']
})
export class ViewReportedByUsersComponent implements OnInit {
  rowData: any;
  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  closeModal() {
    this.modalService.dismissAll();
  }

}
