import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CasesService } from 'src/app/components/cases/services/cases.service';

@Component({
  selector: 'app-custom-buttons',
  templateUrl: './custom-buttons.component.html',
  styleUrls: ['./custom-buttons.component.scss']
})
export class CustomButtonsComponent implements OnInit {
  @Input() rowData!: any;
  @Output() click: EventEmitter<any> = new EventEmitter(this.rowData);
  constructor(
    private router: Router,
    private caseService: CasesService
  ) { }

  ngOnInit(): void {
  }
  seeCaseDetails(rowData?) {
    // this.router.navigate(['cases/cases/case-details', rowData._id], { queryParams: rowData, skipLocationChange: true });
    this.caseService.case.next(rowData);
    this.router.navigate(['cases/cases/case-details'], { queryParams: { id: rowData._id } });
  }

}
