<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-xl-12">
            <div class="card tab2-card">
                <div class="card-body">
                    <ngb-tabset class="tab-coupon profile-tabs">
                        <ngb-tab>
                            <ng-template ngbTabTitle>
                                <app-feather-icons [icon]="'settings'" class="px-2"></app-feather-icons>Configuration Settings
                            </ng-template>
                            <ng-template ngbTabContent>
                                <div class="tab-pane fade show active" id="top-profile" role="tabpanel"
                                    aria-labelledby="top-profile-tab">
                                    <h5 class="f-w-600 px-2"> Configuration Settings</h5>
                                    <form [formGroup]="configSettingForm">
                                        <div class="table-responsive profile-table">
                                            <table class="table table-responsive">
                                                <tbody>
                                                    <tr>
                                                        <td class="align-middle">Alert Contact Number:</td>
                                                        <td class="align-middle">
                                                            <input type="text" formControlName="contactNo"
                                                                class="form-control shadow-none">
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="align-middle">Google Places API Key :</td>
                                                        <td class="align-middle">
                                                            <input type="text" formControlName="apiKey"
                                                                class="form-control shadow-none">
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="align-middle">Sendgrid Key:</td>
                                                        <td class="align-middle">
                                                            <input type="text" formControlName="sendGridKey"
                                                                class="form-control shadow-none">
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="align-middle">Sendgrid Default Mail:</td>
                                                        <td class="align-middle">
                                                            <input type="text" formControlName="sendGridEmail"
                                                                class="form-control shadow-none">
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </form>
                                    <div class="px-2">
                                          <button class="btn btn-primary" type="submit" (click)="saveDetails()">Save</button>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->