import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/service/http.service';
import { UrlsService } from 'src/app/shared/service/urls.service';

@Injectable({
    providedIn: 'root'
})
export class StaticContentService {

    constructor(private urlsService: UrlsService, private http: HttpService) { }

    addFaq(data: any) {
        return this.http.postData('faq/create', data);
    }
    getFaq() {
        return this.http.getData('faq/get');
    }

    updateFaq(faqId: string, data: any) {
        return this.http.putData('faq/update/' + faqId, data);
    }

    deleteFaq(faqId: string) {
        return this.http.deteleData('faq/delete/' + faqId);
    }

    // contactUs
    getContactUs() {
        return this.http.getData('contactUs/get');
    }

    updateContactUs(contactId: string, data: any) {
        return this.http.putData('contactUs/update/' + contactId, data);
    }

    addContactUs(data: any) {
        return this.http.postData('contactUs/create', data)
    }

    // TermsAndConditions
    getTermsAndConditions() {
        return this.http.getData('terms/conditions/get');
    }

    addTermsAndConditions(data: any) {
        return this.http.postData('terms/conditions/create', data);
    }

    updateTermsAndConditions(TcId: string, data: any) {
        return this.http.putData('terms/conditions/update/' + TcId, data);
    }

    deleteTermsAndConditions(id: any) {
        return this.http.deteleData('terms/conditions/delete/' + id);
    }

    // Policy
    getPolicy() {
        return this.http.getData('policy/get');
    }
    addPolicy(data: any) {
        return this.http.postData('policy/create', data);
    }
    updatePolicy(PcId: string, data: any) {
        return this.http.putData('policy/update/' + PcId, data);
    }
    deletePolicy(PcId: string) {
        return this.http.deteleData('policy/delete/' + PcId);
    }

    //help
    getHelp() {
        return this.http.getData('help/get');
    }

    addHelp(data: any) {
        return this.http.postData('help/create', data)
    }

    updateHelp(id: any, data: any) {
        return this.http.putData('help/update/' + id, data)
    }

    deleteHelp(id: string) {
        return this.http.deteleData('help/delete/' + id);
    }
}

export interface Faq {
    description: string,
    _id: string
}

export interface contactUs {
    name: string,
    description: string,
    email: string,
    _id: string
}

