import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from 'src/app/shared/service/http.service';

@Injectable({
  providedIn: 'root'
})
export class CasesService {
  public case = new BehaviorSubject<any>('');
  constructor(private http: HttpService) { }

  getMissingCases(page: any, limit: any, search: any) {
    // return this.http.getData('missingCase/get');
    return this.http.getData(`missingCase/allCasesForAdmin?page=${page}&limit=${limit}&searchBy=${search}`);
  }
  getCaseById(id: any) {
    return this.http.getData(`missingCase/get/${id}`)
  }
  deleteMissingCase(id?: string) {
    return this.http.deteleData(`missingCase/delete/${id}`);
  }
  getDiscussion(id?: string) {
    return this.http.getData(`missingCase/discussion/${id}`);
  }
}
